<template>
    <v-container class="white-bg">
        <v-col>
            <div>
                <h3 class="primary--text text-center"><b>{{$t("YourPaymentTransactionStatus")}}</b></h3>
            </div>
            <v-row class="pt-4 d-flex justify-space-between border-bottom">
                <v-col cols="6">
                    {{$t("Status")}}
                </v-col>
                <v-col class="text-right" cols="6">
                    {{$t(status_detail.status)}}
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between border-bottom">
                <v-col cols="6">
                    {{$t("Notes")}}
                </v-col>
                <v-col class="text-right" cols="6">
                    {{$t(status_detail.notes)}}
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between border-bottom">
                <v-col cols="4">
                    {{$t("OrderID")}}
                </v-col>
                <v-col class="text-right" cols="8">
                    {{status_detail.order_id}}
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between border-bottom">
                <v-col cols="6">
                    {{$t("TransactionAmount")}}
                </v-col>
                <v-col class="text-right" cols="6">
                    {{Intl.NumberFormat().format(status_detail.payment_amount)}}
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between border-bottom">
                <v-col cols="6">
                    {{$t("PaymentChannelConfiguration")}}
                </v-col>
                <v-col class="text-right" cols="6">
                    {{Intl.NumberFormat().format(status_detail.configuration_amount)}}
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between border-bottom">
                <v-col cols="6">
                    {{$t("DisburmentConfiguration")}}
                </v-col>
                <v-col class="text-right" cols="6">
                    {{Intl.NumberFormat().format(status_detail.disburment_config)}}
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between border-bottom">
                <v-col cols="6">
                    {{$t("PaymentAmount")}}
                </v-col>
                <v-col class="text-right" cols="6">
                    {{Intl.NumberFormat().format(status_detail.pay_amount)}}
                </v-col>
            </v-row>
            <v-row class="d-flex justify-space-between border-bottom">
                <v-col cols="auto">
                    {{$t("PaymentChannel")}}
                </v-col>
                <v-col class="text-right" cols="auto">
                    {{status_detail.payment_channel}}
                </v-col>
            </v-row>
        </v-col>

        <v-footer fixed app width="auto" color="white" elevation="3">
            <v-container class="ma-0 pa-0">
                <v-row>
                    <v-col
                        class="ma-0 pa-2"
                        lg="2"
                        md="3"
                        cols="12"
                        align-self="center"
                    >
                        <v-btn
                            rounded
                            block
                            class="text-capitalize background"
                            @click="close()"
                        >
                        {{ $t("Home") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            </v-footer>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            status_detail: {
                status: "",
                notes: "",
                order_id: "",
                transaction_amount: "",
                total_amount: "",
                payment_channel: ""
            }
        }
    },
    methods: {
        close() {
            this.$router.push({ name: "Home" })
        }
    },
    created() {
        this.$store.dispatch("orchestrator/paymentDetails", this.$route.params.payment_token).then((response) => {
            if (response.status) {
                const dataResp = response.data

                this.status_detail.status = String(dataResp.status).toLocaleLowerCase()
                this.status_detail.payment_amount = dataResp.amount
                this.status_detail.pay_amount = dataResp.amount

                const ticket_id = String(dataResp.reference_data.ticket_id).replace('TC-', 'ACASIA-')
                this.status_detail.order_id = ticket_id

                this.status_detail.configuration_amount = 0
                this.status_detail.disburment_config = 0
            }
        })
    }
}
</script>
<style scoped>
.border-bottom {
    border-bottom: 1px solid #d3caca
}

.white-bg {
    background: white
}

.background {
    background-color: #2b9635 !important;
    color: white;
}
</style>